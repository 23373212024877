import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: { id: "G-G184PZM5WK" },
    appName: "Stream Landing",
    pageTrackerScreenviewEnabled: true
  },
  router
);

import VueToastr from "vue-toastr";

Vue.use(VueToastr, {});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
