<template>
  <div class="row no-gutters w-100">
    <iframe
      id="stream-player"
      :src="`https://player.osidex.stream/${mediaType}/${videoId}`"
      class="iframe-video-player"
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;"
      allowfullscreen="true"
    />
  </div>
</template>

<script>
export default {
  name: "Preview",
  data: () => {
    return {
      mediaType: "live",
      videoId: "6092cfed44a727001245a710"
    };
  },
  mounted() {
    const { type, id } = this.$route.query;

    if (type) {
      this.mediaType = type;
    }

    if (id) {
      this.videoId = id;
    }
  }
};
</script>

<style>
.iframe-video-player {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0;
}
</style>
