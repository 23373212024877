<template>
  <div class="row no-gutters w-100">
    <div class="ui-bg-overlay bg-dark opacity-50"></div>

    <div class="d-flex col flex-column text-white px-4 px-sm-5">
      <div class="d-flex align-items-center mt-5">
        <div class="ui-w-120">
          <div class="w-100 position-relative" style="padding-bottom: 54%">
            <img
              alt="OSIDEX Logo"
              class="osidex-logo"
              src="https://osidex.io/assets/img/brand/white.png"
            />
          </div>
        </div>
        <div class="text-large ml-3">
          Stream
        </div>
      </div>

      <div class="py-5 my-auto">
        <div class="display-4 text-expanded mb-2">We're currently in</div>
        <h1 class="display-2 font-weight-bolder text-expanded">PRIVATE BETA</h1>
        <p class="text-big">
          Enter your email below to receive product update news.
        </p>

        <div class="coming-soon-subscribe pt-5 mt-2 mb-auto">
          <div class="form-row">
            <div class="col">
              <input
                v-model="email"
                class="form-control form-control-lg form-control-inverted font-secondary"
                placeholder="Enter your email..."
                type="text"
              />
            </div>
            <div class="col-sm-3 mt-2 mt-sm-0">
              <button
                :disabled="submit_disabled"
                class="btn btn-primary btn-lg btn-block font-secondary text-expanded px-0"
                type="button"
                @click="subscribeToNewsletter"
              >
                <small class="font-weight-bold">NOTIFY ME</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="theme-bg-dark d-flex col-lg-5 col-xl-4 align-items-center py-5 px-4 px-sm-5"
    >
      <div class="flex-shrink-1">
        <h5 class="font-weight-bold theme-text-white mb-4">
          WHO <span class="text-primary">WE ARE</span>
        </h5>
        <p class="theme-text-white font-weight-light">
          With the ever increasing demand of delivering high-quality media
          content to thousands, or even tens of thousands, of concurrent
          participants, our goal is to make sure that everyone can participate
          in your broadcasts, without breaking the bank. With the current costs
          of streaming to high volumes of viewers, it can cost thousands of
          dollars for a simple broadcast to your audience.

          <br /><br />

          <u>We're here to change that.</u>

          <br /><br />

          No viewer limit. No quality limit. Reliably deliver live 4k60fps
          streams to your clients, with <i>ease</i>. <b>Like it should be</b>.
        </p>

        <h5 class="font-weight-bold theme-text-white mt-5 mb-4">
          INTERESTED? <span class="text-primary">CONTACT US</span>
        </h5>
        <p class="theme-text-white font-weight-light">
          ✉️ &nbsp;
          <a
            class="align-middle theme-text-white"
            href="mailto:contact+stream@osidex.io"
            >contact+stream@osidex.io</a
          >
        </p>

        <p class="theme-text-white font-weight-light bottom">
          Made with <span class="text-heart">&hearts;</span> by
          <a href="https://osidex.io">OSIDEX</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => {
    return {
      email: "",
      submit_disabled: false
    };
  },
  methods: {
    subscribeToNewsletter() {
      if (!this.email) {
        this.$toastr.e(
          "Please make sure the email you entered is valid.",
          "Failed to subscribe to news letter"
        );
        return;
      }

      this.submit_disabled = true;

      this.$http
        .post(`https://live-api.osidex.stream/v1/marketing/subscribe`, {
          email: this.email,
          topics: "releases"
        })
        .then(response => {
          this.submit_disabled = false;

          if (response.status === 200) {
            this.$toastr.s(
              "You have successfully been subscribed to our newsletter",
              "Subscribed to news letter"
            );
            return;
          }

          this.$toastr.e(
            "Please make sure the email you entered is valid.",
            "Failed to subscribe to news letter"
          );
        })
        .catch(() => {

          this.submit_disabled = false;

          this.$toastr.e(
            "Please make sure the email you entered is valid.",
            "Failed to subscribe to news letter"
          );
        });
    }
  }
};
</script>
